<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 font-c7 w-4/5"
            >
              Nouveau {{ type }}
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-4 bg-white p-6 pb-12">
            <div class="text-left text-c12 mt-6">
              Nom *
            </div>
            <inputo
              class="mt-2"
              height="50px"
              placeholder="TOK"
              @info="retourNom"
            />

            <div class="mt-6">
              <div class="text-left text-c12 mt-6">
                Prénom(s) *
              </div>
              <inputo
                  height="50px"
                  class="mt-2"
                  placeholder="Cédric"
                  @info="retourPrenom"
              />
            </div>

            <div class="mt-6">
              <div class="text-left text-c12 mt-6">
                Téléphone
              </div>
              <phone-number
                  height="50px"
                  class="mt-2"
                  placeholder="97 97 97 97"
                  @info="retourPhone"
              />
            </div>

            <div class="mt-6">
              <div class="text-left text-c12 mt-6">
                Email
              </div>
              <inputo
                  type="email"
                  height="50px"
                  class="mt-2"
                  placeholder="tok@gmail.com"
                  @info="retourEmail"
              />
            </div>

            <div class="mt-6">
              <div class="text-left text-c12 mt-6">
                Adresse
              </div>
              <inputo
                  height="50px"
                  class="mt-2"
                  placeholder="Cotonou"
                  @info="retourAdresse"
              />
            </div>

            <div
              v-if="error !== null"
              class="mt-6 text-red text-c14"
            >
              {{ error }}
            </div>
          </div>

          <div class="flex">
            <div class="w-full">
              <buton
                label="Enregistrer"
                radius="0px 0px 12px 12px"
                height="55px"
                :charge="charge"
                @oga="continuer"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import fermer from '../../assets/icons/fermer.svg'
import inputo from '../helper/form/input'
import apiroutes from "../../router/api-routes"
import http from "../../plugins/https"
import phoneNumber from '../helper/form/phoneNumber'

export default {
  name: 'Success',
  components: { PopupBase, buton, inputo, phoneNumber },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        fermer
      },
      response: {
        phone: '',
        email: '',
        adresse: ''
      },
      error: null,
      charge: false
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    continuer () {
      this.charge = true
      this.error = null
      if (this.response.nom.length > 0 && this.response.prenom.length > 0 ) {
        let body = {
            fullname: this.response.nom + ' ' + this.response.prenom,
            phone: this.response.phone,
            type: this.type.toUpperCase(),
            meta: {
              email: this.response.email,
              adresse: this.response.adresse
            }
        }
        if (this.type !== 'commercial'){
          body.type = 'DOCTOR'
        }
        this.save(body)
      } else {
        this.charge = false
        this.error = 'Veuillez renseigné les champs obligatoire pour continuer'
      }
    },

    save (body) {
      http.post(apiroutes.baseURL + apiroutes.createCommercial, body)
          .then(response => {
            this.charge = false
            this.$emit('oga', true)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

    retourNom (answer) {
      this.response.nom = answer
    },

    retourPrenom (answer) {
      this.response.prenom = answer
    },

    retourEmail (answer) {
      this.response.email = answer
    },

    retourAdresse (answer) {
      this.response.adresse = answer
    },

    retourPhone (answer) {
      this.response.phone = answer
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
