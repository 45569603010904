<template>
  <div class="">
    <popupDelete :activation="activePopup" :type="type" :donnes="item" v-if="activePopup" @oga="retourDelete"/>
    <popupUpdate :activation="activeUpdate" :type="type" :donnes="item" v-if="activeUpdate" @oga="retourUpdate"/>
    <div class="w-full flex text-c16 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/3 flex items-center">
        <nameLabel
            :first="item.fullname"
            :second="item.fullname.substr(1,1)"
            height="32px"
            width="32px"
            size="12px"
        />
        <div class="ml-3">
          {{ item.fullname }}
        </div>
      </div>

      <div class="w-1/4 ml-4 flex items-center">
       {{item.phone}}
      </div>

      <div class="w-1/4 flex items-center ml-4">
          <div class="text-c16">
            <span v-if="item.meta.email !== undefined">{{item.meta.email}}</span>
            <span v-if="item.meta.email === undefined"> - </span>
          </div>
      </div>

      <div class="w-1/5 ml-4">
        <icon
            :data="icons.update"
            height="20"
            width="20"
            class="mr-4 cursor-pointer"
            @click="updateCommercial"
            original
        />
        <icon
            :data="icons.remov"
            height="20"
            width="20"
            class="cursor-pointer"
            @click="deleteCommercial"
            original
        />
      </div>

    </div>
  </div>
</template>

<script>
import remov from '../../../assets/icons/delete.svg'
import update from '../../../assets/icons/update.svg'
import down from '../../../assets/icons/down.svg'
import nameLabel from '../../helper/add/nameLabel'
import popupDelete from '../../popup/deleteCommercial'
import popupUpdate from '../../popup/updateCommerciaux'

export default {
  name: "Index",
  components: {
    nameLabel,
    popupDelete,
    popupUpdate
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      icons: {
        update,
        down,
        remov
      },
      activePopup: false,
      activeUpdate: false
    }
  },
  methods: {
    deleteCommercial () {
      this.activePopup = true
    },

    retourDelete (answer) {
      if (answer) {
        this.$emit('delete', true)
      }
      this.activePopup = false
    },

    updateCommercial () {
      this.activeUpdate = true
    },

    retourUpdate (answer) {
      if (answer) {
        this.donnes = answer
      }
      this.activeUpdate = false
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
